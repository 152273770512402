<template>
  <NuxtLink
    v-if="to"
    :class="btnClasses"
    :to="to"
    :target="target"
  >
    <slot />
  </NuxtLink>
  <a
    v-else-if="href"
    :class="btnClasses"
    :href="href"
    :target="target"
  >
    <slot />
  </a>
  <button
    v-else
    :type="nativeType"
    :disabled="loading"
    :class="btnClasses"
  >
    <slot v-if="!loading" />
    <loader
      v-else
      class="h-6 w-6 mx-auto"
      :class="`text-${colorShades['text']}`"
    />
  </button>
</template>

<script>
export default {
  name: "VButton",

  props: {
    color: {
      type: String,
      default: "nt-blue",
    },

    shade: {
      type: String,
      default: "normal",
    },

    shadow: {
      type: String,
      default: null,
    },

    fontWeight: {
      type: String,
      default: null,
    },

    size: {
      type: String,
      default: "medium",
    },

    nativeType: {
      type: String,
      default: null,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    href: {
      type: String,
      default: null,
    },

    to: {
      type: Object,
      default: null,
    },

    target: {
      type: String,
      default: "_self",
    },
  },

  computed: {
    btnClasses() {
      const sizes = this.sizes
      const colorShades = this.colorShades
      const shadow = this.shadow || this.colorShades.shadow || "md"
      const fontWeight =
        this.fontWeight || this.colorShades.fontWeight || "semibold"
      return `${sizes["p-y"]} ${sizes["p-x"]}
        ${colorShades?.main} ${colorShades?.hover} ${colorShades?.ring} ${colorShades["ring-offset"]}
        ${colorShades?.text} transition ease-in duration-200 text-center text-${sizes?.font} font-${fontWeight} shadow-${shadow} focus:outline-none focus:ring-2
        focus:ring-offset-2 rounded-lg hover:no-underline`
    },
    colorShades() {
      if (this.color === "nt-blue") {
        return {
          main: "bg-nt-blue-default",
          hover: "hover:bg-blue-600",
          ring: "focus:ring-nt-blue-light",
          "ring-offset": "focus:ring-offset-nt-blue-lighter",
          text: "text-white",
        }
      }
      if (this.color === "white") {
        return {
          main: "bg-white border border-gray-300 dark:border-gray-600 dark:bg-notion-dark-light",
          hover: "hover:bg-gray-100",
          ring: "focus:ring-nt-blue-light",
          "ring-offset": "focus:ring-offset-nt-blue-lighter",
          text: "text-gray-700 dark:text-gray-300",
          shadow: "sm",
          fontWeight: "normal",
        }
      }
      if (this.shade === "lighter") {
        return {
          main: "bg-" + this.color + "-200",
          hover: "hover:bg-" + this.color + "-300",
          ring: "focus:ring-" + this.color + "-100",
          "ring-offset": "focus:ring-offset-" + this.color + "-50",
          text: "text-gray-900",
        }
      }
      if (this.shade === "light") {
        return {
          main: "bg-" + this.color + "-400",
          hover: "hover:bg-" + this.color + "-500",
          ring: "focus:ring-" + this.color + "-300",
          "ring-offset": "focus:ring-offset-" + this.color + "-150",
          text: "text-white",
        }
      }
      return {
        main: "bg-" + this.color + "-600",
        hover: "hover:bg-" + this.color + "-700",
        ring: "focus:ring-" + this.color + "-500",
        "ring-offset": "focus:ring-offset-" + this.color + "-200",
        text: "text-white",
      }
    },
    sizes() {
      if (this.size === "small") {
        return {
          font: "sm",
          "p-y": "py-1",
          "p-x": "px-2",
        }
      }
      return {
        font: "base",
        "p-y": "py-2",
        "p-x": "px-4",
      }
    },
  },
}
</script>
