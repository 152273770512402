<template>
  <modal
    :show="show"
    @close="close"
  >
    <div class="overflow-hidden">
      <div class="flex items-center justify-center">
        <div class="flex-grow my-10 sm:my-0 sm:px-10">
          <h2 class="text-nt-blue text-3xl font-bold z-10 my-6">
            Your Workspaces
          </h2>
          <p
            v-if="!error"
            class="mb-0 font-medium"
          >
            Here are your connected notion workspaces. You can connect Notion
            Tools to another workspace using the button below.
          </p>
          <p
            v-else
            class="mb-0 font-medium text-red-500"
          >
            There is a connection issue with one of your workspace. Please
            re-establish the connection with Notion now.
          </p>

          <div
            v-if="loading"
            class="my-5"
          >
            <div
              class="border border-nt-blue-light shadow rounded-md p-4 max-w-sm w-full mx-auto mb-5"
            >
              <div class="animate-pulse flex space-x-4">
                <div class="rounded-full bg-nt-blue-light h-12 w-12" />
                <div class="flex-1 space-y-4 py-1">
                  <div class="space-y-2">
                    <div class="h-4 bg-nt-blue-light rounded" />
                    <div class="h-4 bg-nt-blue-light rounded w-5/6" />
                  </div>
                </div>
              </div>
            </div>
            <div
              class="border border-nt-blue-light shadow rounded-md p-4 max-w-sm w-full mx-auto"
            >
              <div class="animate-pulse flex space-x-4">
                <div class="rounded-full bg-nt-blue-light h-12 w-12" />
                <div class="flex-1 space-y-4 py-1">
                  <div class="space-y-2">
                    <div class="h-4 bg-nt-blue-light rounded" />
                    <div class="h-4 bg-nt-blue-light rounded w-5/6" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-else
            class="mt-5"
          >
            <div
              v-for="workspace in workspaces"
              :key="workspace.id"
              class="border border-nt-blue-light shadow rounded-md p-4 mb-5 max-w-sm w-full mx-auto"
            >
              <div class="flex space-x-4">
                <img
                  v-if="isUrl(workspace.icon)"
                  :src="workspace.icon"
                  :alt="workspace.name + ' icon'"
                  class="rounded-full h-12 w-12"
                >
                <div
                  v-else
                  class="rounded-full bg-nt-blue-lighter h-12 w-12 text-2xl pt-2 text-center"
                  v-text="workspace.icon"
                />
                <div class="flex-1 flex items-center space-y-4 py-1">
                  <div
                    v-if="
                      error &&
                        error.workspace_id &&
                        workspace.id == error.workspace_id
                    "
                  >
                    <p
                      class="font-bold"
                      v-text="workspace.name"
                    />
                    <p class="text-sm text-red-500">
                      Connection Error
                    </p>
                  </div>
                  <p
                    v-else
                    class="font-bold"
                    v-text="workspace.name"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="mt-2 text-center sm:text-left mb-8">
            <login-with-airtable
              text="Connect a Workspace"
              class="mt-2"
              @success="onAirtableConnectSuccess"
            />
            <v-button
              color="gray"
              shade="light"
              class="mt-2"
              @click="close"
            >
              Close
            </v-button>
          </div>
        </div>
        <img
          src="/img/icons/neutral.png"
          alt="Notion character neutral"
          class="hidden w-40 sm:block"
        >
      </div>
    </div>
  </modal>
</template>

<script>
import LoginWithAirtable from "~/components/global/LoginWithAirtable.vue"
import { computed } from "vue"
import { useWorkspacesStore } from "../../stores/workspaces"

export default {
  name: "WorkspacesModal",
  components: { LoginWithAirtable },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    error: {
      type: Object,
      required: false,
    },
  },
  emits: ['close', 'workspace-added'],
  setup() {
    const workspacesStore = useWorkspacesStore()
    return {
      workspacesStore,
      workspaces: computed(() => workspacesStore.content),
    }
  },
  data() {
    return {
      loading: false,
    }
  },

  computed: {
    workspaceEndpoint: () => "/airtable/workspaces",
  },

  watch: {
    show(val) {
      if (val) {
        this.loadWorkspaces()
      }
    },
  },

  mounted() {
    this.loadWorkspaces()
  },

  methods: {
    onAirtableConnectSuccess(workspace) {
      this.workspacesStore.addOrUpdate(workspace)
      this.$emit("workspace-added", workspace)
    },
    loadWorkspaces() {
      this.loading = true
      airtableFormsFetch(this.workspaceEndpoint).then((data) => {
        this.workspacesStore.set(data)
        this.loading = false
      })
    },
    close() {
      this.$emit("close")
    },
    isUrl(str) {
      const pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i",
      ) // fragment locator
      return !!pattern.test(str)
    },
  },
}
</script>
