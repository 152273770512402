<template>
  <div class="database-input">
    <div class="relative">
      <div
        role="button"
        class="absolute right-0 text-gray-400 text-sm group"
        @click="loadDatabases"
      >
        Refresh
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-4 w-4 inline transition transform group-hover:-rotate-180"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
          />
        </svg>
      </div>
      <v-select
        :value="value"
        :data="orderDatabases"
        label="Select a Notion database"
        :option-key="optionKey"
        :loading="loading"
        searchable
        :search-keys="['title']"
        :remote="remoteSearch"
        placeholder="Select a Notion table"
        @update:model-value="select"
      >
        <template #selected="{ option }">
          <div class="flex items-center space-x-3">
            <span
              v-if="option.title !== ''"
              class="block truncate"
            >
              {{ option.title }}
            </span>
            <span
              v-else
              class="block truncate text-gray-500"
            > Untitled </span>
          </div>
        </template>
        <template #empty-placeholder>
          <div class="text-gray-400 w-full text-left">
            No database found
          </div>
        </template>
        <template #option="{ option, selected }">
          <span
            class="flex items-center space-x-3 hover:text-white dark:text-white"
          >
            <span
              v-if="option.title !== ''"
              class="block truncate"
              :class="{ 'font-normal': !selected, 'font-semibold': selected }"
            >
              {{ option.title }}
            </span>
            <span
              v-else
              class="block truncate text-gray-400 dark:text-gray-600"
              :class="{ 'font-normal': !selected, 'font-semibold': selected }"
            >
              Untitled
            </span>
          </span>

          <span
            v-if="selected"
            class="absolute inset-y-0 right-0 flex items-center pr-4 dark:text-white"
          >
            <svg
              class="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clip-rule="evenodd"
              />
            </svg>
          </span>
        </template>
      </v-select>
      <div
        role="button"
        class="absolute right-0 text-gray-400 text-sm group mt-1"
        @click.prevent="showManualInput = true"
      >
        Use database URL
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-4 w-4 inline transition transform group-hover:-rotate-180"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M12.586 4.586a2 2 0 112.828 2.828l-3 3a2 2 0 01-2.828 0 1 1 0 00-1.414 1.414 4 4 0 005.656 0l3-3a4 4 0 00-5.656-5.656l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5zm-5 5a2 2 0 012.828 0 1 1 0 101.414-1.414 4 4 0 00-5.656 0l-3 3a4 4 0 105.656 5.656l1.5-1.5a1 1 0 10-1.414-1.414l-1.5 1.5a2 2 0 11-2.828-2.828l3-3z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
    </div>

    <modal
      :show="showManualInput"
      @close="closeManualInputModal"
    >
      <div class="w-full">
        <text-input
          name="database_url"
          :form="dbUrlForm"
          label="paste your Notion database URL"
          :validation="false"
          placeholder="https://www.notion.so/1f060afcbbfe4de582c6342b2969c180?v=06bc586061f14ccbb078f396b9ae7335"
        />
        <p
          v-if="manualInputError"
          class="text-red-500"
          v-text="manualInputError"
        />
      </div>
      <div class="w-full text-center mb-5">
        <v-button
          :loading="loading"
          color="nt-blue"
          class="mt-2 px-5 text-center"
          @click="notionUrlChanged"
        >
          Submit
        </v-button>
        <v-button
          class="ml-4"
          color="gray"
          shade="light"
          @click.prevent="showManualInput = false"
        >
          Close
        </v-button>
      </div>
      <p class="text-sm text-gray-500 text-center my-5">
        <a
          href="#"
          class="text-nt-blue hover:underline"
          @click.prevent="openCrisp"
        >It's not working, I need help</a>
      </p>
      <video
        class="w-full rounded shadow-lg mx-auto mb-4"
        autoplay
        loop
        muted
      >
        <source
          src="/img/pages/create_form/share_notion_db.mp4"
          type="video/mp4"
        >
        Your browser does not support the video tag.
      </video>
    </modal>
  </div>
</template>

<script>
import { computed } from "vue"
import { useDatabasesStore } from "../../../stores/databases"

export default {
  name: "DatabaseInput",
  props: {
    workspaceId: { type: Number, required: true },
    value: { type: Object, required: true },
    optionKey: { type: String, default: "id" },
    label: { type: String, default: null },
    required: { type: Boolean, default: false },
  },

  emits: ['input', 'no-databases-found'],

  setup() {
    const databasesStore = useDatabasesStore()
    return {
      databasesStore,
      databases: computed(() => databasesStore.content),
      loading: computed(() => databasesStore.loading),
    }
  },
  data() {
    return {
      showManualInput: false,
      search: "",
      manualInputError: "",
      dbUrlForm: {
        database_url: null,
      },
    }
  },
  computed: {
    databaseSearchEndpoint: () => "/airtable/workspaces/{id}/databases/search/",
    databaseEndpoint: () =>
      "/airtable/workspaces/{workspaceId}/databases/{database_id}",
    orderDatabases() {
      const  databases = clonedeep(this.databases)
      return databases.sort((a, b) => {
        return a.title > b.title ? 1 : -1
      })
    },
  },
  watch: {
    workspaceId(val, oldVal) {
      if (val !== oldVal) {
        this.$emit("input", null)
        this.search = ""
        this.loadDatabases(true)
      }
    },
    "dbUrlForm.database_url"() {
      this.notionUrlChanged()
    },
  },
  mounted() {
    this.loadDatabases(true)
  },
  methods: {
    openCrisp() {
      this.$crisp.push([
        "do",
        "helpdesk:article:open",
        ["en", "how-to-share-a-notion-database-with-notionforms-3bkzhy"],
      ])
    },
    select(value) {
      this.$emit("input", value)
    },
    closeManualInputModal() {
      this.showManualInput = false
      this.loadDatabases()
    },
    remoteSearch(val) {
      this.search = val
      this.loadDatabases()
    },
    loadDatabases(initialLoad = false) {
      this.databasesStore.startLoading()
      this.databasesStore.set([])
      airtableFormsFetch(
        this.databaseSearchEndpoint.replace("{id}", this.workspaceId) +
          this.search,
      )
        .then((data) => {
          this.databasesStore.set(data)

          if (initialLoad && data.length === 0) {
            this.$emit("no-databases-found")
          }
          // Refresh data for loaded db - emit it again
          if (this.value !== null) {
            this.$emit("input", this.databasesStore.getById(this.value.id))
          }
        })
        .finally(() => {
          this.databasesStore.stopLoading()
        })
    },
    /**
     * On manual url change, validate that we can access the database
     */
    notionUrlChanged() {
      this.manualInputError = ""
      const regexpDatabaseId = /[a-zA-Z0-9]*/gi
      let url = this.dbUrlForm.database_url

      if (!url) {
        this.manualInputError = "Please enter Notion table URL."
        return
      }

      if (url.includes("?")) {
        // Remove parameters
        url = url.split("?")[0]
      }

      // Find alphanumerical groups
      const databaseId = [...url.match(regexpDatabaseId)].find((group) => {
        return group !== "" && group.length === 32
      })
      if (!databaseId) {
        this.manualInputError = "Provided URL is not a valid Notion table URL."
        return
      }

      // Query database
      this.databasesStore.startLoading()
      airtableFormsFetch(
        this.databaseEndpoint
          .replace("{workspaceId}", this.workspaceId)
          .replace("{database_id}", this.formatDatabaseId(databaseId)),
      )
        .then((data) => {
          this.databasesStore.save(data)
          this.$emit("input", this.databasesStore.getById(data.id))
        })
        .catch(() => {
          this.manualInputError =
            "Something went wrong. No Notion table found with provided url."
        })
        .finally(() => {
          this.databasesStore.stopLoading()
        })
    },
    formatDatabaseId(id) {
      return [
        id.slice(0, 8),
        id.slice(8, 12),
        id.slice(12, 16),
        id.slice(16, 20),
        id.slice(20),
      ].join("-")
    },
  },
}
</script>
