<template>
  <input-wrapper v-bind="inputWrapperProps">
    <template #label>
      <slot name="label" />
    </template>
    <v-select
      ref="vselect"
      v-model="compVal"
      :data="filteredRelations"
      :label="label"
      option-key="id"
      emit-key="id"
      :required="required"
      :loading="loading"
      :multiple="multiple"
      searchable
      :remote="remoteSearch"
      :color="color"
      :placeholder="placeholder"
      :uppercase-labels="uppercaseLabels"
      :theme="theme"
      :has-error="hasError"
      :allow-creation="allowCreation"
      :should-allow-creation="shouldAllowCreation"
      :disabled="disabled"
      :help="help"
      :help-position="helpPosition"
      :clearable="clearable"
      :show-clear-button="false"
      @update:model-value="select"
      @update-options="updateOptions"
    >
      <template #selected="{ option, toggle }">
        <div
          v-if="multiple"
          class="flex gap-1 flex-wrap items-center pr-4 w-full"
        >
          <div
            v-for="item in selectedOptions(option)"
            :key="item.id"
            class="py-1 pl-1 rounded flex gap-x-2 bg-gray-100 border border-gray-300 dark:border-gray-600 dark:bg-gray-500 text-xs flex-grow max-w-full truncate text-center items-center"
          >
            <span>
              {{
                item.label !== null && item.label !== '' ? item.label : 'Untitled'
              }}
            </span>
            <button
              class="cursor-pointer group px-1 -ml-1"
              @click.prevent="toggle(item)"
            >
              <Icon
                v-if="!disabled"
                name="heroicons:x-mark-16-solid"
                class=" w-4 h-4 text-gray-300 group-hover:text-gray-600 group-hover:dark:text-gray-400 dark:text-white"
              />
            </button>
          </div>
          <div class="h-0 flex-grow-[100]" />
        </div>
        <div
          v-for="item in selectedOptions(option)"
          v-else
          :key="item.id"
          class="flex items-center gap-x-2"
        >
          <span>
            {{
              item.label !== null && item.label !== '' ? item.label : 'Untitled'
            }}
          </span>
        </div>
      </template>
      <template #option="{ option, selected }">
        <span
          class="flex items-center gap-x-2 dark:text-white cursor-pointer"
        >
          {{
            option.label !== null && option.label !== ""
              ? option.label
              : "Untitled"
          }}
        </span>

        <span
          v-if="selected"
          class="absolute inset-y-0 right-0 flex items-center pr-4 dark:text-white"
        >
          <Icon
            name="heroicons:check-16-solid"
            class="w-5 h-5"
          />
        </span>
      </template>
    </v-select>

    <template #help>
      <slot name="help" />
    </template>

    <template #error>
      <slot name="error" />
    </template>
  </input-wrapper>
</template>

<script>
import clonedeep from "clone-deep"
import { inputProps, useFormInput } from "../useFormInput.js"
import InputWrapper from "../components/InputWrapper.vue"
import Fuse from 'fuse.js'
import { default as _has } from 'lodash/has'

export default {
  name: "RelationInput",
  components: { InputWrapper },
  props: {
    ...inputProps,
    baseId: { type: String, required: false },
    relation: { type: Object, required: false },
    workspaceId: { type: Number, required: false },
    formSlug: { type: String, required: false },
    propertyId: { type: String, required: false },
    filter: { type: Object, required: false },
    usePrivateEndpoint: { type: Boolean, required: false, default: true },
    displayedPropertyId: { type: String, required: false, default: null },
    multiple: { type: Boolean, required: false, default: true },
    allowCreation: { type: Boolean, required: false, default: false },
    useFormRecordsEndpoint: { type: Boolean, required: false, default: false },
    clearable: { type: Boolean, default: false }
  },
  emits: ['update:modelValue'],
  setup(props, context) {
    const relationsStore = useAirtableRelationsStore()
    relationsStore.initForInput(props.name)
    return {
      relationsStore,
      ...useFormInput(props, context),
    }
  },
  data() {
    return {
      searchText: "",
      abortController: null
    }
  },
  computed: {
    loading() {
      return this.relationsStore.loadingItems[this.name]
    },
    relations() {
      return clonedeep(this.relationsStore.getByKey(this.name))?.relations ?? []
    },
    fetchUrl() {
      let ids = null
      if (this.compVal) {
        if (Array.isArray(this.compVal))
          ids = encodeURIComponent(this.compVal.join(','))
        else
          ids = encodeURIComponent(this.compVal)
      }
      if (this.useFormRecordsEndpoint) {
        return '/forms/{slug}/search-records{search}{queryparam}'
          .replace('{slug}', this.formSlug)
          .replace(
            '{search}',
            this.searchText ? `/${encodeURIComponent(this.searchText)}` : ''
          )
          .replace('{queryparam}', ids ? `?ids=${ids}` : '')
      } else if (this.usePrivateEndpoint) {
        return "/airtable/workspaces/{workspaceId}/databases/{baseId}/{databaseId}/search-records{search}"
          .replace("{workspaceId}", this.workspaceId)
          .replace("{baseId}", this.baseId)
          .replace("{databaseId}", this.relation.linkedTableId)
          .replace(
            "{search}",
            this.searchText ? "/" + encodeURIComponent(this.searchText) : "",
          )
      } else {
        return "/forms/{slug}/property/{propertyId}/search-relation{search}"
          .replace("{slug}", this.formSlug)
          .replace("{propertyId}", encodeURIComponent(this.propertyId))
          .replace(
            "{search}",
            this.searchText ? "/" + encodeURIComponent(this.searchText) : "",
          )
      }
    },
    filteredRelations () {
      if (!this.searchText) {
        return clonedeep(this.relations).sort((a, b) => {
          return a.label > b.label ? 1 : -1
        })
      }
      // Fuze search
      const fuse = new Fuse(this.relations, { keys: ['label'] })
      return fuse.search(this.searchText).map((res) => {
        return res.item
      })
    },
    hasMissingRelations () {
      return this.compVal && this.relations.length > 0 && this.compVal.length > 0 && this.relations.filter((item) => {
        return this.compVal.includes(item.id)
      }).length !== this.compVal.length
    },
  },
  watch: {
    compVal (val, oldVal) {
      if (!oldVal && this.hasMissingRelations) {
        // Reset to fetch records explicitely
        if (this.abortController) {
          this.abortController.abort()
        }
        this.extraRecordsFetched = false
        this.loadRelations(true)
      }
    },
  },
  mounted() {
    if (!this.multiple && Array.isArray(this.compVal)) {
      this.compVal = this.compVal[0] ?? null
    }
    if (!this.relationsStore.allLoaded[this.name]) {
      this.loadRelations()
    }
  },
  methods: {
    loadRelations() {
      let requestOptions
      if (this.loading) {
        return
      }
      this.abortController = new AbortController()
      if (this.usePrivateEndpoint) {
       requestOptions = {
          method: "POST",
          body: {
            filter: this.filter,
            displayed_property_id: this.displayedPropertyId,
          },
          signal: this.abortController.signal
        }
        this.relationsStore.loadAll(this.name, this.fetchUrl, requestOptions)
      } else {
        requestOptions = {
          signal: this.abortController.signal
        }
        this.relationsStore.loadAll(this.name, this.fetchUrl, requestOptions)
      }
    },
    select(value) {
      this.$emit("update:modelValue", value)
    },
    remoteSearch(val) {
      this.searchText = val
      this.loadRelations()
    },
    selectedOptions(ids) {
      return this.relations.filter((item) => {
        if (this.multiple) {
          return ids.includes(item.id)
        } else {
          return item.id === ids
        }
      })
    },
    isUrl(url) {
      const pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i",
      ) // fragment locator
      return !!pattern.test(url)
    },
    updateOptions(newItem) {
      if (newItem) {
        this.relationsStore.createRelationItem(this.name, {
          id: newItem.name,
          label: newItem.name,
        })
      }
    },
    shouldAllowCreation(search) {
      if (!this.allowCreation) return false
      if (!this.compVal) return true
      if (!search) return false

      if (this.multiple) {
        return (
          this.compVal.filter((item) => {
            return item.label === search
          }).length === 0
        )
      } else {
        return this.compVal !== search
      }
    },
  },
}
</script>
