import { default as callback9yqL9CWexwMeta } from "/codebuild/output/src3183782542/src/airtableforms/client/pages/airtable/callback.vue?macro=true";
import { default as _91slug_93FsHvLEdU80Meta } from "/codebuild/output/src3183782542/src/airtableforms/client/pages/features/[slug].vue?macro=true";
import { default as index1jHcCEbj2QMeta } from "/codebuild/output/src3183782542/src/airtableforms/client/pages/features/index.vue?macro=true";
import { default as edityj0hnCpDL9Meta } from "/codebuild/output/src3183782542/src/airtableforms/client/pages/forms/[slug]/edit.vue?macro=true";
import { default as indexGg9pfXUrdWMeta } from "/codebuild/output/src3183782542/src/airtableforms/client/pages/forms/[slug]/index.vue?macro=true";
import { default as analytics8gHun0YqdLMeta } from "/codebuild/output/src3183782542/src/airtableforms/client/pages/forms/[slug]/show/analytics.vue?macro=true";
import { default as index5adVUIIUyGMeta } from "/codebuild/output/src3183782542/src/airtableforms/client/pages/forms/[slug]/show/index.vue?macro=true";
import { default as indextRahawm0TmMeta } from "/codebuild/output/src3183782542/src/airtableforms/client/pages/forms/[slug]/show/integrations/index.vue?macro=true";
import { default as shareAwJmfFKJhDMeta } from "/codebuild/output/src3183782542/src/airtableforms/client/pages/forms/[slug]/show/share.vue?macro=true";
import { default as show6JHw5HC1caMeta } from "/codebuild/output/src3183782542/src/airtableforms/client/pages/forms/[slug]/show.vue?macro=true";
import { default as indexZwQe69FZ9GMeta } from "/codebuild/output/src3183782542/src/airtableforms/client/pages/forms/create/index.vue?macro=true";
import { default as homefqsKcaxNTNMeta } from "/codebuild/output/src3183782542/src/airtableforms/client/pages/home.vue?macro=true";
import { default as indexJM5OYx1DkMMeta } from "/codebuild/output/src3183782542/src/airtableforms/client/pages/index.vue?macro=true";
import { default as loginn0iWoIZ2NZMeta } from "/codebuild/output/src3183782542/src/airtableforms/client/pages/login.vue?macro=true";
import { default as callbackEBE9fekmrrMeta } from "/codebuild/output/src3183782542/src/airtableforms/client/pages/oauth/callback.vue?macro=true";
import { default as onboardingpxXCsJnE0KMeta } from "/codebuild/output/src3183782542/src/airtableforms/client/pages/onboarding.vue?macro=true";
import { default as _91token_93t4qh5uqDimMeta } from "/codebuild/output/src3183782542/src/airtableforms/client/pages/password/reset/[token].vue?macro=true";
import { default as indexDGiaBlTLkOMeta } from "/codebuild/output/src3183782542/src/airtableforms/client/pages/password/reset/index.vue?macro=true";
import { default as pricingTVhU4Jk1tAMeta } from "/codebuild/output/src3183782542/src/airtableforms/client/pages/pricing.vue?macro=true";
import { default as privacy_45policy0bfrmEZdc4Meta } from "/codebuild/output/src3183782542/src/airtableforms/client/pages/privacy-policy.vue?macro=true";
import { default as registerDBYSNTMvAKMeta } from "/codebuild/output/src3183782542/src/airtableforms/client/pages/register.vue?macro=true";
import { default as access_45tokensk6AOFFGiJFMeta } from "/codebuild/output/src3183782542/src/airtableforms/client/pages/settings/access-tokens.vue?macro=true";
import { default as accountVZgf3btn0iMeta } from "/codebuild/output/src3183782542/src/airtableforms/client/pages/settings/account.vue?macro=true";
import { default as adminfOHLJxXXPJMeta } from "/codebuild/output/src3183782542/src/airtableforms/client/pages/settings/admin.vue?macro=true";
import { default as billing07IpRR1aQAMeta } from "/codebuild/output/src3183782542/src/airtableforms/client/pages/settings/billing.vue?macro=true";
import { default as connections0Qm2fEOe8dMeta } from "/codebuild/output/src3183782542/src/airtableforms/client/pages/settings/connections.vue?macro=true";
import { default as custom_45domaine4JpROPxLpMeta } from "/codebuild/output/src3183782542/src/airtableforms/client/pages/settings/custom-domain.vue?macro=true";
import { default as email_45settingsP2fu8fSE88Meta } from "/codebuild/output/src3183782542/src/airtableforms/client/pages/settings/email-settings.vue?macro=true";
import { default as indexLZ78RfksmSMeta } from "/codebuild/output/src3183782542/src/airtableforms/client/pages/settings/index.vue?macro=true";
import { default as password4YFlxBh8IKMeta } from "/codebuild/output/src3183782542/src/airtableforms/client/pages/settings/password.vue?macro=true";
import { default as profilevqud5pWh6MMeta } from "/codebuild/output/src3183782542/src/airtableforms/client/pages/settings/profile.vue?macro=true";
import { default as workspacesdeoR8jANwgMeta } from "/codebuild/output/src3183782542/src/airtableforms/client/pages/settings/workspaces.vue?macro=true";
import { default as settingsEswGgDDm3yMeta } from "/codebuild/output/src3183782542/src/airtableforms/client/pages/settings.vue?macro=true";
import { default as errorzguTOlX3XUMeta } from "/codebuild/output/src3183782542/src/airtableforms/client/pages/subscriptions/error.vue?macro=true";
import { default as successlcmVsZipHoMeta } from "/codebuild/output/src3183782542/src/airtableforms/client/pages/subscriptions/success.vue?macro=true";
import { default as terms_45conditionsnZawCVcTkYMeta } from "/codebuild/output/src3183782542/src/airtableforms/client/pages/terms-conditions.vue?macro=true";
export default [
  {
    name: "airtable-callback",
    path: "/airtable/callback",
    meta: callback9yqL9CWexwMeta || {},
    component: () => import("/codebuild/output/src3183782542/src/airtableforms/client/pages/airtable/callback.vue").then(m => m.default || m)
  },
  {
    name: "features-slug",
    path: "/features/:slug()",
    component: () => import("/codebuild/output/src3183782542/src/airtableforms/client/pages/features/[slug].vue").then(m => m.default || m)
  },
  {
    name: "features",
    path: "/features",
    component: () => import("/codebuild/output/src3183782542/src/airtableforms/client/pages/features/index.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug-edit",
    path: "/forms/:slug()/edit",
    meta: edityj0hnCpDL9Meta || {},
    component: () => import("/codebuild/output/src3183782542/src/airtableforms/client/pages/forms/[slug]/edit.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug",
    path: "/forms/:slug()",
    component: () => import("/codebuild/output/src3183782542/src/airtableforms/client/pages/forms/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: show6JHw5HC1caMeta?.name,
    path: "/forms/:slug()/show",
    meta: show6JHw5HC1caMeta || {},
    component: () => import("/codebuild/output/src3183782542/src/airtableforms/client/pages/forms/[slug]/show.vue").then(m => m.default || m),
    children: [
  {
    name: "forms-slug-show-analytics",
    path: "analytics",
    meta: analytics8gHun0YqdLMeta || {},
    component: () => import("/codebuild/output/src3183782542/src/airtableforms/client/pages/forms/[slug]/show/analytics.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug-show",
    path: "",
    redirect: index5adVUIIUyGMeta?.redirect,
    component: () => import("/codebuild/output/src3183782542/src/airtableforms/client/pages/forms/[slug]/show/index.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug-show-integrations",
    path: "integrations",
    meta: indextRahawm0TmMeta || {},
    component: () => import("/codebuild/output/src3183782542/src/airtableforms/client/pages/forms/[slug]/show/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug-show-share",
    path: "share",
    meta: shareAwJmfFKJhDMeta || {},
    component: () => import("/codebuild/output/src3183782542/src/airtableforms/client/pages/forms/[slug]/show/share.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "forms-create",
    path: "/forms/create",
    meta: indexZwQe69FZ9GMeta || {},
    component: () => import("/codebuild/output/src3183782542/src/airtableforms/client/pages/forms/create/index.vue").then(m => m.default || m)
  },
  {
    name: "home",
    path: "/home",
    meta: homefqsKcaxNTNMeta || {},
    component: () => import("/codebuild/output/src3183782542/src/airtableforms/client/pages/home.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src3183782542/src/airtableforms/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginn0iWoIZ2NZMeta || {},
    component: () => import("/codebuild/output/src3183782542/src/airtableforms/client/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "oauth-callback",
    path: "/oauth/callback",
    alias: ["/oauth/:provider/callback"],
    component: () => import("/codebuild/output/src3183782542/src/airtableforms/client/pages/oauth/callback.vue").then(m => m.default || m)
  },
  {
    name: "onboarding",
    path: "/onboarding",
    meta: onboardingpxXCsJnE0KMeta || {},
    component: () => import("/codebuild/output/src3183782542/src/airtableforms/client/pages/onboarding.vue").then(m => m.default || m)
  },
  {
    name: "password-reset-token",
    path: "/password/reset/:token()",
    meta: _91token_93t4qh5uqDimMeta || {},
    component: () => import("/codebuild/output/src3183782542/src/airtableforms/client/pages/password/reset/[token].vue").then(m => m.default || m)
  },
  {
    name: "password-reset",
    path: "/password/reset",
    meta: indexDGiaBlTLkOMeta || {},
    component: () => import("/codebuild/output/src3183782542/src/airtableforms/client/pages/password/reset/index.vue").then(m => m.default || m)
  },
  {
    name: "pricing",
    path: "/pricing",
    component: () => import("/codebuild/output/src3183782542/src/airtableforms/client/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/codebuild/output/src3183782542/src/airtableforms/client/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "register",
    path: "/register",
    meta: registerDBYSNTMvAKMeta || {},
    component: () => import("/codebuild/output/src3183782542/src/airtableforms/client/pages/register.vue").then(m => m.default || m)
  },
  {
    name: settingsEswGgDDm3yMeta?.name,
    path: "/settings",
    meta: settingsEswGgDDm3yMeta || {},
    component: () => import("/codebuild/output/src3183782542/src/airtableforms/client/pages/settings.vue").then(m => m.default || m),
    children: [
  {
    name: "settings-access-tokens",
    path: "access-tokens",
    component: () => import("/codebuild/output/src3183782542/src/airtableforms/client/pages/settings/access-tokens.vue").then(m => m.default || m)
  },
  {
    name: "settings-account",
    path: "account",
    meta: accountVZgf3btn0iMeta || {},
    component: () => import("/codebuild/output/src3183782542/src/airtableforms/client/pages/settings/account.vue").then(m => m.default || m)
  },
  {
    name: "settings-admin",
    path: "admin",
    meta: adminfOHLJxXXPJMeta || {},
    component: () => import("/codebuild/output/src3183782542/src/airtableforms/client/pages/settings/admin.vue").then(m => m.default || m)
  },
  {
    name: "settings-billing",
    path: "billing",
    meta: billing07IpRR1aQAMeta || {},
    component: () => import("/codebuild/output/src3183782542/src/airtableforms/client/pages/settings/billing.vue").then(m => m.default || m)
  },
  {
    name: "settings-connections",
    path: "connections",
    meta: connections0Qm2fEOe8dMeta || {},
    alias: ["/settings/connections/callback/:service"],
    component: () => import("/codebuild/output/src3183782542/src/airtableforms/client/pages/settings/connections.vue").then(m => m.default || m)
  },
  {
    name: "settings-custom-domain",
    path: "custom-domain",
    meta: custom_45domaine4JpROPxLpMeta || {},
    component: () => import("/codebuild/output/src3183782542/src/airtableforms/client/pages/settings/custom-domain.vue").then(m => m.default || m)
  },
  {
    name: "settings-email-settings",
    path: "email-settings",
    meta: email_45settingsP2fu8fSE88Meta || {},
    component: () => import("/codebuild/output/src3183782542/src/airtableforms/client/pages/settings/email-settings.vue").then(m => m.default || m)
  },
  {
    name: "settings",
    path: "",
    redirect: indexLZ78RfksmSMeta?.redirect,
    component: () => import("/codebuild/output/src3183782542/src/airtableforms/client/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-password",
    path: "password",
    meta: password4YFlxBh8IKMeta || {},
    component: () => import("/codebuild/output/src3183782542/src/airtableforms/client/pages/settings/password.vue").then(m => m.default || m)
  },
  {
    name: "settings-profile",
    path: "profile",
    meta: profilevqud5pWh6MMeta || {},
    component: () => import("/codebuild/output/src3183782542/src/airtableforms/client/pages/settings/profile.vue").then(m => m.default || m)
  },
  {
    name: "settings-workspaces",
    path: "workspaces",
    meta: workspacesdeoR8jANwgMeta || {},
    component: () => import("/codebuild/output/src3183782542/src/airtableforms/client/pages/settings/workspaces.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "subscriptions-error",
    path: "/subscriptions/error",
    meta: errorzguTOlX3XUMeta || {},
    component: () => import("/codebuild/output/src3183782542/src/airtableforms/client/pages/subscriptions/error.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-success",
    path: "/subscriptions/success",
    meta: successlcmVsZipHoMeta || {},
    component: () => import("/codebuild/output/src3183782542/src/airtableforms/client/pages/subscriptions/success.vue").then(m => m.default || m)
  },
  {
    name: "terms-conditions",
    path: "/terms-conditions",
    component: () => import("/codebuild/output/src3183782542/src/airtableforms/client/pages/terms-conditions.vue").then(m => m.default || m)
  }
]