<template>
  <div class="flex">
    <v-button
      :class="buttonClass"
      color="white"
      :href="redirectUrlEndpoint"
      target="_blank"
    >
      <div class="flex justify-center items-center">
        <span class="dark:text-notion-dark">
          {{ text }}
        </span>
        <Icon
          name="logos:airtable"
          class="w-6 h-6 ml-2"
        />
      </div>
    </v-button>

    <modal
      v-if="errorMessage == 'workspace_already_existing'"
      :show="showModalError"
      @close="closeErrorModal"
    >
      <div class="overflow-hidden">
        <h2 class="text-center text-nt-blue text-2xl font-bold z-10 mt-6">
          Workspace already connected
        </h2>

        <div class="flex items-center justify-center">
          <div class="flex-grow my-10 sm:my-0 sm:px-10">
            <p class="mb-0 font-medium mt-6">
              This Airtable workspace has already been associated with another Airformtable account.
              <template v-if="errorOwner">
                <span class="font-semibold">{{ errorOwner.name }} ({{ errorOwner.email }})</span> is the current owner
                of this workspace.
              </template>
              You have two options:
              <ul class="list-decimal list-inside">
                <li class="mt-4">
                  You or the current owner of the workspace can upgrade your account by subscribing to our Enterprise plan.
                  This will allow both of you to collaborate on forms for this workspace.
                </li>
                <li class="mt-4">
                  You can ask the current owner to remove the workspace from his Airformtable account. This will "free"
                  the workspace, allowing you to use it in Airformtable. <a
                    href="#"
                    @click.prevent="$crisp.push(['do', 'helpdesk:article:open', ['en', 'how-to-disconnect-my-notion-workspace-from-notionforms-1fn53x']])"
                  >Learn how to remove a workspace from a AirtableForm account</a>.
                </li>
              </ul>
            </p>
            <p class="mt-4">
              <a
                href="#"
                @click.prevent="openChat"
              >Talk to us</a> if you have any question.
            </p>
            <div class="mt-4 text-center sm:text-left">
              <v-button
                v-if="showRetry"
                color="nt-blue"
                class="px-10 mr-2"
                @click="retry"
              >
                Retry
              </v-button>
              <fancy-link
                v-if="showUpgradePlan"
                target="_blank"
                :to="{name:'pricing'}"
                color="nt-blue"
                class="px-10 mr-2"
              >
                Upgrade Subscription
              </fancy-link>
              <v-button
                color="gray"
                shade="light"
                @click="closeErrorModal"
              >
                Close
              </v-button>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      v-else
      :show="showModalError"
      @close="closeErrorModal"
    >
      <div class="overflow-hidden">
        <h2 class="text-center text-nt-blue text-4xl font-bold z-10 mt-6">
          Whoops!
        </h2>

        <div class="flex items-center justify-center">
          <div class="flex-grow my-10 sm:my-0 sm:px-10">
            <p class="mb-0 font-medium">
              {{ errorMessage }} <a
                href="#"
                @click.prevent="openChat"
              >Talk to us</a>.
            </p>
            <div class="mt-4 text-center sm:text-left">
              <v-button
                v-if="showRetry"
                color="nt-blue"
                class="px-10 mr-2"
                @click="retry"
              >
                Retry
              </v-button>
              <fancy-link
                v-if="showUpgradePlan"
                target="_blank"
                :to="{name:'pricing'}"
                color="nt-blue"
                class="px-10 mr-2"
              >
                Upgrade Subscription
              </fancy-link>
              <v-button
                color="gray"
                shade="light"
                @click="closeErrorModal"
              >
                Close
              </v-button>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from './Modal.vue'
import VButton from './Button.vue'
import { useAuthStore } from '../stores/auth'
import { useBroadcastChannel } from '@vueuse/core'

export default {
  name: 'LoginWithAirtable',
  components: { VButton, Modal },
  props: {
    text: { type: String, default: 'Login with Airtable' },
    guestWorkspaceUser: { type: Boolean, default: false },
    buttonClass: { type: String, default: ''}
  },
  emits: ['success'],
  setup () {
    const authStore = useAuthStore()
    const workspacesStore = useWorkspacesStore()
    const authBroadcast = useBroadcastChannel('auth')
    return {
      authStore,
      workspacesStore,
      authBroadcast,
      broadcastData: useBroadcastChannel('auth').data,
      crisp: useCrisp(),
    }
  },

  data () {
    return {
      showModalError: false,
      showRetry: false,
      showUpgradePlan: false,
      errorMessage: null,
      errorOwner: null
    }
  },

  computed: {
    redirectUrlEndpoint: () => {
      return useRuntimeConfig().public.apiBase + 'airtable/redirect'
    }
  },
  watch: {
    broadcastData (data) {
      if (data) {
        this.onMessage(data)
      }
    }
  },

  methods: {
    retry () {
      this.closeErrorModal()
      this.login()
    },

    closeErrorModal () {
      this.showModalError = false
      this.errorMessage = null
      this.showUpgradePlan = false
      this.showRetry = false
    },

    openChat () {
      this.crisp.sendTextMessage('Hi! I need help to connect my Airtable workspace. ' +
      this.errorOwner.name + '(' + this.errorOwner.email + ') has already connected it to his Airtable account.')
    },

    async onMessage(message) {
      if (import.meta.server || message.source !== 'airtable_tools') {
        return
      }

      // Handle errors
      if (message.type !== 'success') {
        this.showModalError = true
        this.showRetry = message.retry
        this.showUpgradePlan = message.upgrade
        this.errorMessage = message.message
        this.errorOwner = message.owner
        return
      }

      // For guest users, only emit success
      if (this.guestWorkspaceUser) {
        useAmplitude().logEvent('airtable_guest_logged_in', {
          workspace_id: message.workspace.id,
          workspace_name: message.workspace.name
        })
        this.$emit('success', message.cookie_value)
        return
      }

      // For regular users, re-fetch their data
      this.$emit('success', message.workspace)
      const workspaces = await fetchAllWorkspaces()
      this.workspacesStore.set(workspaces.data.value)

      const userData = await airtableFormsFetch('user')
      this.authStore.setUser(userData)
      useAmplitude().logEvent('airtable_workspace_added', {
        workspace_id: message.workspace.id,
        workspace_name: message.workspace.name
      })
      this.crisp.pushEvent('airtable-connected')
    }
  }
}

</script>
