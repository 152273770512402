<template>
  <div>
    <v-select
      :value="value"
      :data="workspaces"
      :label="label"
      :option-key="optionKey"
      :required="required"
      :loading="loading"
      @update:model-value="select"
    >
      <template #selected="{ option }">
        <div class="flex items-center space-x-3">
          <img
            v-if="isUrl(option.icon)"
            :src="option.icon"
            :alt="option.name + ' icon'"
            class="flex-shrink-0 h-6 w-6 rounded-full"
          >
          <div
            v-else
            class="rounded-full pt-1 text-xs truncate bg-nt-blue-lighter h-6 w-6 text-center"
            v-text="option.icon"
          />
          <span class="block truncate">
            {{ option.name }}
          </span>
        </div>
      </template>
      <template #option="{ option, selected }">
        <span class="flex items-center space-x-3 hover:text-white">
          <img
            v-if="isUrl(option.icon)"
            :src="option.icon"
            :alt="option.name + ' icon'"
            class="flex-shrink-0 h-6 w-6 rounded-full"
          >
          <div
            v-else
            class="rounded-full bg-nt-blue-lighter h-6 w-6 text-sm text-center"
            v-text="option.icon"
          />
          <span
            class="block truncate"
            :class="{ 'font-normal': !selected, 'font-semibold': selected }"
          >
            {{ option.name }}
          </span>
        </span>

        <span
          v-if="selected"
          class="absolute inset-y-0 right-0 flex items-center pr-4 dark:text-white"
        >
          <svg
            class="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
              clip-rule="evenodd"
            />
          </svg>
        </span>
      </template>
    </v-select>
    <p class="text-sm text-gray-600 dark:text-gray-400 mt-2">
      You can't find your workspace?
      <a
        href="#"
        class="text-nt-blue hover:underline"
        @click.prevent="showWorkspaceModal = true"
      >Click here to connect another workspace.</a>
    </p>
    <workspaces-modal
      :show="showWorkspaceModal"
      @workspace-added="workspaceAdded"
      @close="showWorkspaceModal = false"
    />
  </div>
</template>

<script>
import WorkspacesModal from "~/components/airtable/WorkspacesModal.vue"

export default {
  name: "WorkspaceInput",
  components: { WorkspacesModal },
  props: {
    workspaces: { type: Array, required: true },
    value: { type:[Object, Array] ,required: true },
    optionKey: { type: String, default: "id" },
    label: { type: String, default: null },
    required: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
  },
  emits: ['input'],
  data() {
    return {
      showWorkspaceModal: false,
    }
  },
  methods: {
    select(value) {
      this.$emit("input", value)
    },
    workspaceAdded() {
      this.$emit("input", null)
    },
    isUrl(str) {
      try {
        new URL(str)
      } catch (_) {
        return false
      }
      return true
    },
  },
}
</script>
