<template>
  <div
    class="overflow-hidden"
    :class="[theme.fileInput.uploadedFile]"
    :title="file.file.name"
  >
    <div
      v-if="file.src && !isImageHide"
      class="h-20 overflow-hidden flex"
    >
      <img
        class="block object-cover object-center w-full"
        :src="file.src"
        @error="isImageHide = true"
      >
    </div>
    <div
      v-else
      class="h-20 flex items-center justify-center"
    >
      <Icon
        name="heroicons:document"
        class="w-10 h-10 text-gray-500"
      />
    </div>
    <div class="flex gap-2 items-center border-t py-1 px-2">
      <p class="flex-grow text-left truncate text-gray-500 text-xs">
        {{ fileName }}
      </p>
      <a
        v-if="showRemove"
        href="javascript:void(0);"
        class="flex text-gray-400 rounded hover:bg-neutral-50 hover:text-red-500 dark:text-gray-600 p-1"
        role="button"
        title="Remove"
        @click.stop="$emit('remove')"
      >
        <Icon
          name="heroicons:trash"
          class="w-4 h-4"
        />
      </a>
    </div>
  </div>
</template>

<script>
import CachedDefaultTheme from '~/lib/forms/themes/CachedDefaultTheme.js'

export default {
  name: 'UploadedFile',

  props: {
    file: { type: Object, default: null },
    showRemove: { type: Boolean, default: true },
    theme: {
      type: Object, default: () => {
        const theme = inject("theme", null)
        if (theme) {
          return theme.value
        }
        return CachedDefaultTheme.getInstance()
      }
    },
  },

  emits: ['remove'],

  data: () => ({
    isImageHide: false
  }),

  computed: {
    fileName () {
      if (this.file.file.name) {
        return this.file.file.name
      }
      return this.file.url
    }
  },

  mounted () {
  }
}
</script>
