<template />

<script>
import { computed } from "vue"

export default {
  name: "Hotjar",

  setup() {
    const authStore = useAuthStore()
    return {
      authenticated: computed(() => authStore.check),
      user: computed(() => authStore.user),
    }
  },

  computed: {
    isIframe() {
      return window.location !== window.parent.location || window.frameElement
    },
  },

  watch: {
    authenticated() {
      if (this.authenticated) {
        this.loadHotjar()
      }
    },
  },

  mounted() {
    if (import.meta.server) return
    this.loadHotjar()
  },

  methods: {
    loadHotjar() {
      if (!this.authenticated || this.isIframe) return;

      (function (h, o, t, j, a, r) {
        h.hj =
          h.hj ||
          function () {
            (h.hj.q = h.hj.q || []).push(arguments)
          }
        h._hjSettings = { hjid: 5043302, hjsv: 6 }
        a = o.getElementsByTagName("head")[0]
        r = o.createElement("script")
        r.async = 1
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv
        a.appendChild(r)
      })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=")
    },
  },
}
</script>
